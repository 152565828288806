import { useCallback, useEffect } from "react";

import { Box, Center, Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { HEIGHT } from "core/components/Header";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { SharedListComponent } from "flows/Inbound/components/SharedListComponent";
import { useGetInboundingListsQuery } from "flows/Inbound/queries/collaborativeInbound/collaborativeInbound.generated";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useClaimInboundList } from "flows/Inbound/stores/useInboundDropService";
import { useDeleteDraftInboundList } from "flows/Inbound/stores/useInboundPreDropService";
import DeliveryTruckNew from "images/random/delivery-truck-new.svg";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { Spinner } from "ui/Spinner/Spinner";
import { BodyM, HeaderS, TitleM } from "ui/Typography/Typography";

function InboundSharedListPlaceholder() {
  return (
    <Flex
      h={`calc(100% - ${HEIGHT})`}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px="s100"
      gap="s100"
    >
      <HeaderS textAlign="center">
        <FormattedMessage id="flows.inbound.page.inbound-shared-list.header" />
      </HeaderS>
      <BodyM textAlign="center">
        <FormattedMessage id="flows.inbound.page.inbound-shared-list.paragraph" />
      </BodyM>
      <Image src={DeliveryTruckNew} mt="s200" />
    </Flex>
  );
}

export function InboundSharedList() {
  const navigate = useNavigate();
  const { isClaimingList } = useClaimInboundList();
  const resetToInitialState = useInboundStore((state) => state.resetToInitialState);
  useEffect(() => {
    resetToInitialState();
  }, [resetToInitialState]);

  const {
    data,
    loading: isFetchingShareList,
    refetch: refetchInboundingLists,
  } = useGetInboundingListsQuery({
    fetchPolicy: "network-only",
  });

  const { deleteDraftInboundList, isDeletingDraftInboundList } =
    useDeleteDraftInboundList(refetchInboundingLists);

  const sharedLists = data?.getInboundingLists?.lists;
  const sortedLists = [...(sharedLists || [])].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const onClickCreateNewList = useCallback(() => {
    navigate(routes.inbound.selectDelivery);
  }, [navigate]);

  if (isFetchingShareList || isClaimingList || isDeletingDraftInboundList) {
    return (
      <Center h="100%" w="100%">
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="flex-start" px="s100">
      {sortedLists.length ? (
        <Flex w="100%" flexDirection="column" justifyContent="flex-start" gap="s100" pb="72px">
          <Box px="s200" pt="s200">
            <TitleM textAlign="left">
              <FormattedMessage id="flows.inbound.page.inbound-shared-list.inbound-list" />
            </TitleM>
          </Box>
          {sortedLists.map((sharedList) => (
            <SharedListComponent
              key={sharedList.id}
              listId={sharedList.id}
              listName={sharedList.name}
              deliverySSCC={sharedList.sscc}
              createdAt={sharedList.createdAt}
              status={sharedList.status}
              products={sharedList.products}
              deleteDraftInboundList={deleteDraftInboundList}
            />
          ))}
        </Flex>
      ) : (
        <InboundSharedListPlaceholder />
      )}
      <EndDroppingProcessButton
        shouldShowButton
        onClickButton={onClickCreateNewList}
        labelMessageId="flows.inbound.page.inbound-shared-list.button"
        isLoading={false}
        bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
        px="s200"
      />
    </Flex>
  );
}
