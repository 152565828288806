import { useCallback, useEffect, useMemo, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { InboundingListProduct, ListProductStatus } from "__graphql__/types";
import { CollaborativeInboundProgressedAction, PageName } from "analytics/events";
import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { EppoFeatureFlags } from "core/types/flags";
import {
  INBOUND_UNIT_ADD_ORIGIN_MAP,
  useInboundEventTracker,
} from "flows/Inbound/hooks/useInboundEventTracker/useInboundEventTracker";
import { TranslatedSubcategory } from "flows/Inbound/models/subcategory/types";
import {
  GetProductsQuery,
  useGetProductsLazyQuery,
} from "flows/Inbound/queries/collaborativeInbound/collaborativeInbound.generated";
import { useGetUnitsSizesLazyQuery } from "flows/Inbound/queries/product/product.generated";
import { InboundUnitAddOrigin, TranslatedProduct } from "flows/Inbound/stores/inboundStore/types";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { getUniqueHandlingUnitSizes } from "flows/Inbound/stores/inboundStore/utils";
import { countryNameFromCode } from "libs/countryNames";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useBarcodeScanner } from "shared/hooks/useBarcodeScanner";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { Category, TranslatedCategory } from "shared/models/category/types";
import { serializeProductsInSubcategory } from "shared/models/product/serializer";
import { TranslatedProductBySubcategory } from "shared/models/product/types";
import { translatedInboundUnitFromSearchResult } from "shared/models/productSearchUnit/serializer";
import { TranslatedProductSearchUnit } from "shared/models/productSearchUnit/types";
import { useGetNonScannableCategoriesLazyQuery } from "shared/queries/category/category.generated";
import { useGetProductsBySubcategoryV2LazyQuery } from "shared/queries/product/product.generated";
import {
  SearchUnitsByEanQuery,
  useSearchUnitsByEanLazyQuery,
} from "shared/queries/productSearchUnit/productSearchUnit.generated";
import { IntlMessageId } from "shared/types/lang";
import { LetterRange } from "ui/LetterRangeSelector/LetterRangeSelector";
import { getFetchPolicyWithCacheTimeout } from "utils/graphql/graphql";
import { getShelfDetail } from "utils/item";
import { isNullOrUndefined } from "utils/tsHelpers";

import { ProductSearchActionTypes, useProductSearchStore } from "./useProductSearchStore";

const GET_PRODUCTS_BY_SUBCATEGORY_ERROR_TOAST_ID = "get_products_by_subcategory_error_toast_id";
const GET_NON_SCANNABLE_CATEGORIES_ERROR_TOAST_ID = "get_non_scannable_categories_error_toast_id";
const GET_UNITS_BY_EAN_ERROR_TOAST_ID = "get_units_by_ean_error_toast_id";
const GET_PRODUCTS_BY_SKUS_ERROR_TOAST_ID = "get_products_by_skus_error_toast_id";

const letterRangeTemplates: Omit<LetterRange, "disabled">[] = [
  { id: "AD", from: "A", to: "D" },
  { id: "EH", from: "E", to: "H" },
  { id: "IL", from: "I", to: "L" },
  { id: "MP", from: "M", to: "P" },
  { id: "QT", from: "Q", to: "T" },
  { id: "UX", from: "U", to: "X" },
  { id: "YZ", from: "Y", to: "Z" },
];

function normalizeCategoryNameForPhraseKey(name: string): string {
  return name
    .replace(/[^a-zA-Z\s]/g, "")
    .toLowerCase()
    .replace(/\s/g, "-");
}

function nextLetter(letter: string): string {
  const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return ALPHABET[ALPHABET.indexOf(letter) + 1];
}
interface UseFetchCategoriesState {
  categories: Record<string, TranslatedSubcategory[]> | null;
  isLoading: boolean;
}

interface UseFetchProductsBySubcategory {
  products: TranslatedProductBySubcategory[] | null;
  isLoading: boolean;
}

export function useTranslatedNonScannableCategories() {
  const intl = useIntl();
  return useCallback(
    (categories: Category[] | null): TranslatedCategory[] | null => {
      if (isNullOrUndefined(categories)) return null;

      return categories.map((category) => ({
        ...category,
        subcategories: category.subcategories.map((subcategory) => ({
          ...subcategory,
          translatedName: intl.formatMessage({
            id: `flows.inbound.no-ean-search.subcategories.${normalizeCategoryNameForPhraseKey(
              category.name,
            )}.${normalizeCategoryNameForPhraseKey(subcategory.name)}` as IntlMessageId,
            defaultMessage: subcategory.name,
          }),
        })),
      }));
    },
    [intl],
  );
}

export function useSerialiseAndTranslatedProducts() {
  const intl = useIntl();
  return useCallback(
    (categories: Category[] | null): TranslatedCategory[] | null => {
      if (isNullOrUndefined(categories)) return null;

      return categories.map((category) => ({
        ...category,
        subcategories: category.subcategories.map((subcategory) => ({
          ...subcategory,
          translatedName: intl.formatMessage({
            id: `flows.inbound.no-ean-search.subcategories.${normalizeCategoryNameForPhraseKey(
              category.name,
            )}.${normalizeCategoryNameForPhraseKey(subcategory.name)}` as IntlMessageId,
            defaultMessage: subcategory.name,
          }),
        })),
      }));
    },
    [intl],
  );
}

export function useFilteredSortedGroupedAndTranslatedSubcategories() {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const { selectedCategoryName } = useProductSearchStore(
    (state) => ({ selectedCategoryName: state.selectedCategoryName }),
    shallow,
  );

  return useCallback(
    (translatedCategories: TranslatedCategory[] | null): UseFetchCategoriesState["categories"] => {
      if (isNullOrUndefined(translatedCategories) || isNullOrUndefined(selectedCategoryName))
        return null;

      const selectedCategory = translatedCategories.find(
        (category) => category.name === selectedCategoryName,
      );
      const filteredSubcategories = (selectedCategory?.subcategories || []).filter(
        (subcat) => subcat.productsCount > 0,
      );

      const { compare } = new Intl.Collator(appLanguage);
      const sortedSubcategories = filteredSubcategories.sort((s1, s2) =>
        compare(s1.translatedName, s2.translatedName),
      );

      return letterRangeTemplates.reduce<Record<string, TranslatedSubcategory[]>>(
        (subCatGroups, letterRange) => {
          subCatGroups[letterRange.id] = sortedSubcategories.filter(
            (s) =>
              compare(letterRange.from, s.translatedName) === -1 &&
              (isNullOrUndefined(nextLetter(letterRange.to)) ||
                compare(nextLetter(letterRange.to), s.translatedName) === 1),
          );
          return subCatGroups;
        },
        {},
      );
    },
    [selectedCategoryName, appLanguage],
  );
}

export function useLetterRanges(groupedSubcategories: Record<string, TranslatedSubcategory[]>) {
  return useMemo(
    () =>
      letterRangeTemplates.map((template) => ({
        ...template,
        disabled: groupedSubcategories[template.id]?.length === 0,
      })),
    [groupedSubcategories],
  );
}

export function useSafeSelectedLetterRangeId(
  letterRanges: LetterRange[],
  selectedLetterRangeId: string,
) {
  return useMemo(() => {
    const isSelectedRangeDisabled = letterRanges.find(
      (lr) => lr.id === selectedLetterRangeId,
    )?.disabled;
    if (!isSelectedRangeDisabled) return selectedLetterRangeId;

    const firstNonDisabledRange = letterRanges.find((lr) => !lr.disabled);
    return firstNonDisabledRange?.id || selectedLetterRangeId;
  }, [letterRanges, selectedLetterRangeId]);
}

export function useSerializedProduct() {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const transformProductForDisplay = useCallback(
    (
      product: GetProductsQuery["getProducts"]["products"][0],
      inboundingProduct?: InboundingListProduct,
    ): TranslatedProduct => {
      const shelf = product.inventoryEntry.shelfNumber;
      const shelfDetail = shelf ? getShelfDetail(shelf) : null;
      const { quantity = 0, handlingUnitSize = 1, status = null } = inboundingProduct || {};
      const unitSizeForDisplay = handlingUnitSize > 1 ? quantity / handlingUnitSize : quantity;

      return {
        productName: product.name,
        productSku: product.sku,
        productImageUrl: product.imageUrl ?? null,
        isProductBio: product.bio ?? null,
        countryOfOriginName: countryNameFromCode(
          appLanguage,
          product.countryOfOrigin.code?.toUpperCase() ?? null,
        ),
        shelfLetter: shelfDetail?.shelfLetter ?? null,
        shelfNumber: shelfDetail?.shelfNumber ?? null,
        totalInboundQuantity: quantity,
        handlingUnitSize,
        unitSizeForDisplay,
        status,
      };
    },
    [appLanguage],
  );

  return transformProductForDisplay;
}

export function useAddProductsToSelectedProducts() {
  const navigate = useNavigate();
  const { sendInboundProgressed } = useInboundEventTracker();
  const resetTextSearch = useProductSearchStore((state) => state.resetTextSearch);

  const addProducts = useCallback(
    (newProducts: TranslatedProductSearchUnit[], origin: InboundUnitAddOrigin) => {
      if (newProducts.length === 0) return;
      const { inboundUnitsSortedByDate, addInboundUnitToDroppingList, moveDuplicatedProductToTop } =
        useInboundStore.getState();
      const existingProductIdsSet = new Set(inboundUnitsSortedByDate);
      newProducts.forEach((product) => {
        if (existingProductIdsSet.has(product.productSku)) {
          moveDuplicatedProductToTop(product.productSku);
        } else {
          addInboundUnitToDroppingList(product, origin);
          sendInboundProgressed({
            sku: product.productSku,
            action: CollaborativeInboundProgressedAction.ProductAddedToList,
            method: INBOUND_UNIT_ADD_ORIGIN_MAP[origin],
          });
        }
      });
      resetTextSearch();
      navigate(routes.inbound.preDropping);
    },
    [navigate, resetTextSearch, sendInboundProgressed],
  );

  return addProducts;
}

export function useAddProductToSelectedProducts() {
  const addProducts = useAddProductsToSelectedProducts();

  return useCallback(
    (newProduct: TranslatedProductSearchUnit | null, origin: InboundUnitAddOrigin) => {
      if (newProduct) {
        addProducts([newProduct], origin);
      }
    },
    [addProducts],
  );
}

export function useFetchNonScannableCategories() {
  const { showToastUI } = useCustomToast();
  const { resetTextSearch } = useProductSearchStore(
    (state) => ({
      resetTextSearch: state.resetTextSearch,
    }),
    shallow,
  );
  const [state, setState] = useState<UseFetchCategoriesState>({
    categories: null,
    isLoading: false,
  });

  const [translatedCategories, setTranslatedCategories] = useState<TranslatedCategory[] | null>(
    null,
  );

  const handleFetchError = useCallback(
    (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Error while fetching non-scannable subcategories", { errorMessage });
      showToastUI({
        id: GET_NON_SCANNABLE_CATEGORIES_ERROR_TOAST_ID,
        title: "get_non_scannable_categories_error_toast_id",
      });
      resetTextSearch();
    },
    [resetTextSearch, showToastUI],
  );
  const [getNonScannableCategories] = useGetNonScannableCategoriesLazyQuery({
    fetchPolicy: getFetchPolicyWithCacheTimeout({ key: "getNonScannableCategories", minutes: 60 }),
    onError: handleFetchError,
  });

  const translateCategories = useTranslatedNonScannableCategories();
  const filterSubcategories = useFilteredSortedGroupedAndTranslatedSubcategories();

  const fetchCategories = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const { data } = await getNonScannableCategories();
      if (data?.getNonScannableCategories?.categories) {
        const translated = translateCategories(data.getNonScannableCategories.categories);
        setTranslatedCategories(translated);
      } else {
        setTranslatedCategories(null);
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [getNonScannableCategories, handleFetchError, translateCategories]);

  useEffect(() => {
    if (translatedCategories) {
      const groupedSubcategories = filterSubcategories(translatedCategories);
      setState((prevState) => ({ ...prevState, categories: groupedSubcategories }));
    }
  }, [translatedCategories, filterSubcategories]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return state;
}

export function useFetchProductsBySubcategory() {
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const { selectedSubcategoryID, setSearchState } = useProductSearchStore(
    (state) => ({
      selectedSubcategoryID: state.selectedSubcategoryID,
      setSearchState: state.setSearchState,
    }),
    shallow,
  );

  const [state, setState] = useState<UseFetchProductsBySubcategory>({
    products: null,
    isLoading: false,
  });

  const handleFetchError = useCallback(
    (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Error while fetching products by subcategory", { errorMessage });
      showToastUI({
        id: GET_PRODUCTS_BY_SUBCATEGORY_ERROR_TOAST_ID,
        title: "get_products_by_subcategory_error_toast_id",
      });
      setSearchState(ProductSearchActionTypes.ACTIVATE_CATEGORY_SEARCH);
      navigate(routes.inbound.manualSearch);
    },
    [navigate, setSearchState, showToastUI],
  );

  const [getProductsBySubcategory] = useGetProductsBySubcategoryV2LazyQuery({
    fetchPolicy: "no-cache",
    onError: handleFetchError,
  });

  const fetchProductsBySubcategory = useCallback(async () => {
    if (selectedSubcategoryID) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getProductsBySubcategory({
          variables: {
            input: {
              subcategoryId: selectedSubcategoryID,
            },
          },
        });
        if (data) {
          const serialized = serializeProductsInSubcategory(data);
          const translated = serialized?.map((product) => ({
            ...product,
            countryOfOriginName:
              countryNameFromCode(appLanguage, product.countryOfOriginCode) ?? null,
          }));
          setState((prevState) => ({ ...prevState, products: translated }));
        } else {
          setState((prevState) => ({ ...prevState, products: null }));
        }
      } catch (error) {
        handleFetchError(error);
      } finally {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    }
  }, [appLanguage, getProductsBySubcategory, handleFetchError, selectedSubcategoryID]);

  useEffect(() => {
    fetchProductsBySubcategory();
  }, [fetchProductsBySubcategory]);

  return state;
}

export function useFetchProductsByEan(ctx?: string) {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const addProducts = useAddProductsToSelectedProducts();
  const [isScannedProductLoading, setIsScannedProductLoading] = useState(false);
  const { showToastUI } = useCustomToast();
  const { sendSegmentTrackEvent } = useAnalytics();
  const { processBarcode } = useBarcodeScanner();
  const setInboundUIState = useInboundStore((state) => state.setInboundUIState);
  const handleError = useCallback(
    (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Error while fetching units by ean", { errorMessage });
      showToastUI({
        id: GET_UNITS_BY_EAN_ERROR_TOAST_ID,
        title: "get_units_by_ean_error_toast_id",
        description: "get_units_by_ean_error_toast_description",
      });
      sendSegmentTrackEvent("errorShown", {
        screen_name: PageName.INBOUND_PAGE,
        component_value: "scan_request_failed",
      });
    },
    [sendSegmentTrackEvent, showToastUI],
  );
  const [searchUnitsByEan] = useSearchUnitsByEanLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleError,
  });

  const onScan = useCallback(
    async (scannedValue: string) => {
      const { skusWithUnconfirmedQuantities } = useInboundStore.getState();
      const hasUnconfirmedQuantities = skusWithUnconfirmedQuantities.length > 0;
      if (isScannedProductLoading) return;
      if (hasUnconfirmedQuantities) {
        setInboundUIState({ isUnverifiedQuantityModalVisible: true });
        return;
      }
      const ean = processBarcode(scannedValue);
      if (!ean) return;
      setIsScannedProductLoading(true);
      try {
        const { data } = await searchUnitsByEan({
          variables: {
            searchUnitsByEanInput: { ean },
            ...(ctx === "inbounding" && { isAlwaysInbound: true }),
          },
        });

        const units = data?.searchUnitsByEan?.units || [];
        if (!data || units.length === 0) {
          setInboundUIState({ isScanningFailedModalVisible: true });
          sendSegmentTrackEvent("errorShown", {
            screen_name: PageName.INBOUND_PAGE,
            component_value: "scanning_failed",
            component_content: scannedValue,
          });
          return;
        }

        const uniqueProductSkus = new Set<string>();
        // Filter out duplicate units
        const filteredUnits = units.reduce((acc, unit) => {
          if (!uniqueProductSkus.has(unit.productSku)) {
            uniqueProductSkus.add(unit.productSku);
            acc.push(unit);
          }
          return acc;
        }, [] as SearchUnitsByEanQuery["searchUnitsByEan"]["units"]);

        const translatedUnits = translatedInboundUnitFromSearchResult(filteredUnits, appLanguage);
        addProducts(translatedUnits, InboundUnitAddOrigin.Scan);
      } catch (error) {
        handleError(error);
      } finally {
        setIsScannedProductLoading(false);
      }
    },
    [
      addProducts,
      appLanguage,
      ctx,
      handleError,
      isScannedProductLoading,
      processBarcode,
      searchUnitsByEan,
      sendSegmentTrackEvent,
      setInboundUIState,
    ],
  );

  return { onScan, isScannedProductLoading };
}

export function useFetchUnitsSizesLazyQuery() {
  const { isFeatureEnabled: isListVerificationHUCheckEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.LIST_VERIFICATION_HU_CHECK,
  );

  const handleFetchError = useCallback((error: any) => {
    const errorMessage = error instanceof Error ? error.message : "Unknown error";
    datadogRum.addError("Error while fetching Units Sizes", { errorMessage });
    return {};
  }, []);

  const [getUnitsSizes] = useGetUnitsSizesLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleFetchError,
  });

  return useCallback(
    async (skus: string[], isAlwaysInbound?: boolean): Promise<Record<string, number[]>> => {
      if (!isListVerificationHUCheckEnabled) return {};
      try {
        const { data } = await getUnitsSizes({
          variables: {
            input: { skus },
            ...(isAlwaysInbound && { isAlwaysInbound: true }),
          },
        });

        const products = data?.getProducts?.products;

        if (!products || products.length === 0) return {};

        return products.reduce<Record<string, number[]>>((result, product) => {
          if (product?.sku) {
            result[product.sku] = getUniqueHandlingUnitSizes(product.units);
          }
          return result;
        }, {});
      } catch (error) {
        handleFetchError(error);
        return {};
      }
    },
    [getUnitsSizes, handleFetchError, isListVerificationHUCheckEnabled],
  );
}

export function useFetchProductsBySkus() {
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const serializeProduct = useSerializedProduct();
  const setInboundPreviewProducts = useInboundStore((state) => state.setInboundPreviewProducts);

  const handleError = useCallback(
    (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error occurred.";
      datadogRum.addError("Error while fetching products by SKUs.", { errorMessage });
      showToastUI({
        id: GET_PRODUCTS_BY_SKUS_ERROR_TOAST_ID,
        title: "get_products_by_skus_error_toast_id",
      });
    },
    [showToastUI],
  );

  const [fetchProducts, { loading: isFetchingProducts }] = useGetProductsLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleError,
  });

  const fetchInboundPreviewProducts = useCallback(
    async (products?: InboundingListProduct[] | null) => {
      if (!products) return;
      const skus = products.map((product) => product.sku);
      if (!skus) return;

      try {
        const { data } = await fetchProducts({
          variables: { input: { skus } },
        });
        const inboundingMap = new Map(products.map((p) => [p.sku, p]));
        const sortedProducts = (data?.getProducts.products || []).reduce(
          (
            acc: {
              otherProducts: TranslatedProduct[];
              inboundedProducts: TranslatedProduct[];
            },
            product,
          ) => {
            const inboundingProduct = inboundingMap.get(product.sku);
            const serializedProduct = serializeProduct(product, inboundingProduct);
            if (serializedProduct.status === ListProductStatus.list_product_status_inbounded) {
              acc.inboundedProducts.push(serializedProduct);
            } else {
              acc.otherProducts.push(serializedProduct);
            }
            return acc;
          },
          { otherProducts: [], inboundedProducts: [] },
        );

        setInboundPreviewProducts([
          ...sortedProducts.otherProducts,
          ...sortedProducts.inboundedProducts,
        ]);

        if (data) {
          navigate(routes.inbound.previewProducts);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [fetchProducts, handleError, navigate, serializeProduct, setInboundPreviewProducts],
  );

  return { fetchInboundPreviewProducts, isFetchingProducts };
}
