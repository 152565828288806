import { useCallback, useEffect, useRef, useState, useMemo } from "react";

import { Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

import { routes } from "config/routes";
import { AddItemsToSharedListButton } from "flows/Inbound/components/AddItemsToSharedListButton";
import { InboundListVerificationNoChecks } from "flows/Inbound/components/InboundListVerificationNoChecks";
import { PreDroppingListPreparationItem } from "flows/Inbound/components/PreDroppingListPreparationItem";
import { SharedListCreationConfirmationModal } from "flows/Inbound/components/SharedListCreationConfirmationModal";
import { SharedListUnsavedChangesWarningModal } from "flows/Inbound/components/SharedListUnsavedChangesWarningModal";
import { useInboundEventTracker } from "flows/Inbound/hooks/useInboundEventTracker/useInboundEventTracker";
import { useSortedActiveVerificationChecks } from "flows/Inbound/hooks/useSortedActiveVerificationChecks";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useSharedListCreator } from "flows/Inbound/stores/useSharedListCreator";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { Banner } from "ui/Banner/Banner";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { TitleM } from "ui/Typography/Typography";
import { preventScroll } from "utils/domEvents";

const LIST_VERIFICATION_COMPLETE_TOAST_ID = "list_verification_complete_toast";

export function InboundSharedListVerification() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { showToastUI } = useCustomToast();
  const { sendInboundVerificationUpdate } = useInboundEventTracker();
  const [nextLocation, setNextLocation] = useState(routes.inbound.root);

  const {
    sharedListId,
    inboundUIState,
    completeListVerificationCheck,
    setInboundUIState,
    resetToInitialState,
  } = useInboundStore((state) => ({
    sharedListId: state.sharedListId,
    inboundUIState: state.inboundUIState,
    completeListVerificationCheck: state.completeListVerificationCheck,
    setInboundUIState: state.setInboundUIState,
    resetToInitialState: state.resetToInitialState,
  }));

  const { isAddingItems, handleAddItemsModalSave, handleAddItemsModalCancel } =
    useSharedListCreator();

  const checks = useSortedActiveVerificationChecks();

  const bannersRef = useRef<HTMLDivElement>(null);
  const listScrollContainerRef = useRef<HTMLDivElement>(null);
  const [currentCheckIndex, setCurrentCheckIndex] = useState(0);

  const selectCheckWithIndex = useCallback(
    (index: number) => {
      setCurrentCheckIndex(index);
      setInboundUIState({ skuFocusedForQuantityInput: checks[index]?.sku });

      if (bannersRef.current && bannersRef.current.children[index]) {
        bannersRef.current.scrollTo({
          left: (bannersRef.current.children[index] as HTMLElement).offsetLeft,
          behavior: "smooth",
        });
      }
    },
    [checks, setInboundUIState],
  );

  const checksCompleted = useMemo(() => {
    return currentCheckIndex === checks.length - 1 && checks[currentCheckIndex].isCompleted;
  }, [currentCheckIndex, checks]);

  const handleCompleteCheck = useCallback(
    (checkKey: string) => {
      completeListVerificationCheck(checkKey);
      sendInboundVerificationUpdate({ checkKey, currentCheckIndex });
      if (currentCheckIndex === checks.length - 1) {
        setInboundUIState({ isListValidationActive: false });
        showToastUI({
          id: LIST_VERIFICATION_COMPLETE_TOAST_ID,
          status: "success",
          containerStyle: { alignItems: "center", marginBottom: "4.5rem" },
          title: "pages.inbound.list-verification.completed-toast",
        });
      } else if (currentCheckIndex < checks.length - 1) {
        selectCheckWithIndex(currentCheckIndex + 1);
      }
    },
    [
      checks.length,
      completeListVerificationCheck,
      currentCheckIndex,
      selectCheckWithIndex,
      sendInboundVerificationUpdate,
      setInboundUIState,
      showToastUI,
    ],
  );
  useEffect(() => {
    if (!sharedListId) navigate(routes.inbound.root);
  }, [navigate, sharedListId]);

  useEffect(() => {
    if (checks.length > 0) {
      const firstIncompleteCheckIndex = checks.findIndex((check) => !check.isCompleted);
      if (firstIncompleteCheckIndex >= 0) {
        selectCheckWithIndex(firstIncompleteCheckIndex);
      }
    } else {
      setInboundUIState({ isListValidationActive: false });
    }
  }, [checks, selectCheckWithIndex, setInboundUIState]);

  useEffect(() => {
    const bannersRefCurrent = bannersRef.current;
    if (bannersRefCurrent) {
      bannersRefCurrent.addEventListener("wheel", preventScroll, { passive: false });
      bannersRefCurrent.addEventListener("touchmove", preventScroll, { passive: false });
    }
    return () => {
      bannersRefCurrent?.removeEventListener("wheel", preventScroll);
      bannersRefCurrent?.removeEventListener("touchmove", preventScroll);
    };
  }, []);

  const blockerFunction = (args: any) => {
    if (args.nextLocation.pathname !== args.currentLocation.pathname) {
      setNextLocation(args.nextLocation.pathname);
      const isBlockedPath =
        args.nextLocation.pathname !== routes.inbound.root &&
        args.nextLocation.pathname !== routes.inbound.preDropping;
      if (isBlockedPath) {
        return true;
      }
    }
    return false;
  };
  const blocker = useBlocker(blockerFunction);

  return (
    <Page h="100%" isFull isBgGrey data-testid="inbound-list-verification-page">
      <NavigationHeader
        title={intl.formatMessage({ id: "pages.inbound.list-verification.title" })}
        onClickGoBack={() => navigate(-1)}
        rightElement={
          checks.length > 0 && <TitleM>{`${currentCheckIndex + 1}/${checks.length}`}</TitleM>
        }
      />
      {checks.length === 0 ? (
        <InboundListVerificationNoChecks />
      ) : (
        <Flex direction="column" h="100%" w="100%">
          <Flex
            zIndex={1}
            overflowX="scroll"
            ref={bannersRef}
            css={{ "&::-webkit-scrollbar": { display: "none" } }}
          >
            {checks.map((check) => (
              <Banner
                key={check.key}
                title={intl.formatMessage({ id: check.title })}
                description={intl.formatMessage(...check.description)}
                minW="100%"
                mb="s100"
                descriptionSize="s"
              />
            ))}
          </Flex>
          <Flex
            direction="column"
            pos="relative"
            overflowY="scroll"
            w="100%"
            h="288px"
            ref={listScrollContainerRef}
            css={{ "&::-webkit-scrollbar": { display: "none" } }}
          >
            {checks.map((check) => (
              <PreDroppingListPreparationItem
                key={check.key}
                sku={check.sku}
                showSwipeButtons={false}
                allowBlur={false}
                defaultTabBarState={false}
                onSubmitQuantityChange={() => handleCompleteCheck(check.key)}
                customScrollContainerRef={listScrollContainerRef}
              />
            ))}
          </Flex>
        </Flex>
      )}
      {(checksCompleted || checks.length === 0) && <AddItemsToSharedListButton />}
      <SharedListCreationConfirmationModal
        isOpen={inboundUIState.isAddToSharedListModalVisible}
        onClickYes={handleAddItemsModalSave}
        onClickNo={handleAddItemsModalCancel}
      />
      <SharedListUnsavedChangesWarningModal
        isOpen={blocker.state === "blocked"}
        onClickYes={() => {
          blocker.proceed?.();
          resetToInitialState();
          navigate(nextLocation);
        }}
        onClickNo={() => {
          blocker.reset?.();
        }}
      />
      <SpinnerModal isOpen={isAddingItems} />
    </Page>
  );
}
