/* eslint-disable react/no-array-index-key */
import { useCallback, useRef } from "react";

import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { InboundingListProduct, InboundingListStatus, ListProductStatus } from "__graphql__/types";
import { routes } from "config/routes";
import { useLayoutStore } from "core/stores/useLayoutStore";
import useSwipeScroll from "shared/hooks/useSwipeScroll";
import { useFetchProductsBySkus } from "shared/stores/useProductSearchService";
import {
  ThreedotsIcon,
  InventoryIcon,
  NewProfileIcon,
  FilledPencilIcon,
  TrashIcon,
} from "ui/Icons/Icons";
import { SideButton } from "ui/SideButton/SideButton";
import { SwipeScrollWrapper } from "ui/SwipeScrollWrapper";
import { DetailL, TitleXS } from "ui/Typography/Typography";
import { getFormattedDate } from "utils/formats";
import { formatImage } from "utils/image";

import { useGetMultipleDesadvsRolliIdLazyQuery } from "../queries/despatchAdvice/despatchAdvice.generated";
import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import { useClaimInboundList } from "../stores/useInboundDropService";

type SharedListComponentProps = {
  listId: string;
  listName: string;
  deliverySSCC: string;
  createdAt: string;
  status: InboundingListStatus;
  products?: InboundingListProduct[] | null;
  deleteDraftInboundList(listId: string): void;
};

const getFormattedProductImages = (products: InboundingListProduct[] | null) => {
  return products
    ?.map((product?: InboundingListProduct) => product?.imageUrl || null)
    .filter((url: string | null): url is string => url !== null)
    .slice(0, 5);
};

function HeaderSection({
  listName,
  status,
  onDeleteDraftSharedList,
  onClaimSharedList,
  onEditSharedListName,
}: {
  listName: string;
  status: InboundingListStatus;
  onDeleteDraftSharedList(): void;
  onClaimSharedList(): void;
  onEditSharedListName(): void;
}) {
  return (
    <Flex
      justifyContent="space-between"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="grey.200"
      alignItems="center"
      pb="s100"
    >
      <Flex alignItems="center">
        <InventoryIcon boxSize="24px" />
        <TitleXS fontWeight="medium" ml={2}>
          {listName}
        </TitleXS>
      </Flex>
      {(status === InboundingListStatus.inbounding_list_status_unclaimed ||
        status === InboundingListStatus.inbounding_list_status_draft) && (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<ThreedotsIcon boxSize="24px" />}
            variant="unstyled"
            aria-label="Options"
          />
          <MenuList minWidth="140px" borderRadius="sm" width="auto" mt="-45px" boxShadow="lg">
            {status === InboundingListStatus.inbounding_list_status_draft && (
              <MenuItem
                icon={<TrashIcon boxSize={6} />}
                sx={{
                  _hover: {
                    bg: "transparent",
                  },
                }}
                onClick={onDeleteDraftSharedList}
                paddingY="s150"
              >
                <FormattedMessage id="flows.inbound.components.shared-list-component.menu-delete" />
              </MenuItem>
            )}
            {status === InboundingListStatus.inbounding_list_status_unclaimed && (
              <MenuItem
                icon={<NewProfileIcon boxSize={6} />}
                sx={{
                  _hover: {
                    bg: "transparent",
                  },
                }}
                onClick={onClaimSharedList}
                paddingY="s150"
              >
                <FormattedMessage id="flows.inbound.components.shared-list-component.menu-claim" />
              </MenuItem>
            )}
            <MenuItem
              icon={<FilledPencilIcon boxSize={6} />}
              sx={{
                _hover: {
                  bg: "transparent",
                },
              }}
              onClick={onEditSharedListName}
              paddingY="s150"
            >
              <FormattedMessage id="flows.inbound.components.shared-list-component.menu-edit" />
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
}

function ProductSection({ products }: { products?: InboundingListProduct[] | null }) {
  if (!products) return null;

  const remainingProductsCount = products.length > 5 ? products.length - 5 : 0;
  const productImages = getFormattedProductImages(products);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex align="center" gap={1.5}>
        {productImages &&
          productImages.map((imageUrl, index) => (
            <Box key={index} borderRadius="sm" overflow="hidden" boxShadow="sm">
              <Image
                src={imageUrl}
                srcSet={`${formatImage(imageUrl, 38, 38)} 1x, ${formatImage(imageUrl, 76, 76)} 2x`}
                alt={imageUrl}
                boxSize="38px"
              />
            </Box>
          ))}
      </Flex>
      {remainingProductsCount > 0 && (
        <Box
          borderRadius="sm"
          overflow="hidden"
          boxShadow="sm"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="grey.100"
          boxSize="38px"
        >
          <Text color="grey.500">+{remainingProductsCount}</Text>
        </Box>
      )}
    </Flex>
  );
}

function FooterSection({
  totalProducts,
  totalNonInboundedProducts,
  createdAt,
}: {
  totalProducts: number;
  totalNonInboundedProducts: number;
  createdAt: string;
}) {
  const intl = useIntl();
  const date = getFormattedDate(createdAt, intl);
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box w="40%">
        <DetailL color="grey.500">
          <FormattedMessage id="flows.inbound.components.shared-list-component.date-created" />
        </DetailL>
        <TitleXS>{date}</TitleXS>
      </Box>

      <Box w="60%">
        <DetailL color="grey.500">
          <FormattedMessage id="flows.inbound.components.shared-list-component.products-not-inbounded" />
        </DetailL>
        <TitleXS>
          {intl.formatMessage(
            { id: "flows.inbound.components.shared-list-component.total-products" },
            { totalNonInboundedProducts, totalProducts },
          )}
        </TitleXS>
      </Box>
    </Flex>
  );
}

export function SharedListComponent({
  listId,
  listName,
  deliverySSCC,
  createdAt,
  status,
  products,
  deleteDraftInboundList,
}: SharedListComponentProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRefFromStore = useLayoutStore((state) => state.scrollContainerRef);
  const { handleSwipeScroll, isScrolledLeft } = useSwipeScroll(
    scrollContainerRefFromStore,
    wrapperRef,
  );

  const { claimInboundList } = useClaimInboundList();
  const {
    setDeliverySSCC,
    setSharedListId,
    setSharedListName,
    updateDespatchAdviceItems,
    setInboundUIState,
  } = useInboundStore(
    (state) => ({
      setDeliverySSCC: state.setDeliverySSCC,
      setSharedListId: state.setSharedListId,
      setSharedListName: state.setSharedListName,
      updateDespatchAdviceItems: state.updateDespatchAdviceItems,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );

  const { fetchInboundPreviewProducts: fetchProductsBySkus } = useFetchProductsBySkus();

  const [fetchDesadvs] = useGetMultipleDesadvsRolliIdLazyQuery({
    onCompleted: (data) =>
      updateDespatchAdviceItems(data?.getMultipleDesadvsRolliID?.despatchAdvices ?? null),
    onError: (error) => {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
      datadogRum.addError("Error fetching despatch advices for delivery SSCC:", { errorMessage });
    },
  });

  const handleListClick = useCallback(async () => {
    setSharedListId(listId);
    setSharedListName(listName);
    if (deliverySSCC) {
      setDeliverySSCC(deliverySSCC);
      await fetchDesadvs({ variables: { input: { rolliID: deliverySSCC } } });
    }
    if (status === InboundingListStatus.inbounding_list_status_draft) {
      navigate(routes.inbound.preDropping);
      return;
    }
    await fetchProductsBySkus(products);
  }, [
    deliverySSCC,
    fetchDesadvs,
    fetchProductsBySkus,
    listId,
    listName,
    navigate,
    products,
    setDeliverySSCC,
    setSharedListId,
    setSharedListName,
    status,
  ]);

  const onDeleteDraftSharedList = useCallback(() => {
    deleteDraftInboundList(listId);
  }, [deleteDraftInboundList, listId]);

  const onClickClaimSharedList = useCallback(() => {
    setDeliverySSCC(deliverySSCC);
    claimInboundList(listId);
  }, [claimInboundList, deliverySSCC, listId, setDeliverySSCC]);

  const onEditSharedListName = useCallback(() => {
    setInboundUIState({ isSharedListNameEditActive: true });
    handleListClick();
  }, [handleListClick, setInboundUIState]);

  const notInboundedProducts = (products || []).filter(
    (product) => product.status !== ListProductStatus.list_product_status_inbounded,
  );

  const mainComponent = (
    <Flex
      direction="column"
      bg="white"
      borderRadius={isScrolledLeft ? "none" : "md"}
      p={4}
      gap={4}
      cursor="pointer"
    >
      <HeaderSection
        listName={listName}
        onDeleteDraftSharedList={onDeleteDraftSharedList}
        onClaimSharedList={onClickClaimSharedList}
        onEditSharedListName={onEditSharedListName}
        status={status}
      />
      <Flex direction="column" gap={4} onClick={handleListClick}>
        <ProductSection products={notInboundedProducts} />
        <FooterSection
          totalProducts={products?.length ?? 0}
          totalNonInboundedProducts={notInboundedProducts?.length ?? 0}
          createdAt={createdAt}
        />
      </Flex>
    </Flex>
  );

  const scrolledComponent = (
    <Box flex="0 0 137px">
      <SideButton
        color="marine.500"
        bgColor="marine.200"
        icon={<NewProfileIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "flows.inbound.components.shared-list-component.button",
          defaultMessage: "Claim list",
        })}
        onClick={onClickClaimSharedList}
        data-testid="remove-product"
        height="100%"
        borderRightRadius="md"
      />
    </Box>
  );

  return (
    <SwipeScrollWrapper
      mainComponent={mainComponent}
      handleSwipeScroll={handleSwipeScroll}
      scrolledComponent={scrolledComponent}
      showSwipeButtons={status === InboundingListStatus.inbounding_list_status_unclaimed}
      ref={wrapperRef}
    />
  );
}
