import { useCallback, useRef } from "react";

import { Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { Outlet, useNavigate } from "react-router";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { useFetchClaimedProducts } from "flows/Inbound/stores/useInboundDropService";
import { Page } from "shared/components/Page";
import { FileBlankListIcon, FileBlankLockIcon } from "ui/Icons/Icons";
import { TabList } from "ui/Tabs/TabList";
import { TabPanel } from "ui/Tabs/TabPanel";
import { TitleM } from "ui/Typography/Typography";

export function CollaborativeInbound() {
  const intl = useIntl();
  const navigate = useNavigate();
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const { fetchClaimedProducts } = useFetchClaimedProducts();
  const handleClaimedListClick = useCallback(async () => {
    await fetchClaimedProducts();
  }, [fetchClaimedProducts]);
  const { pageName, withTabList } = useLayoutStore((state) => ({
    pageName: state.pageName,
    withTabList: state.withTabList,
  }));

  if (!withTabList) return <Outlet />;

  const isSharedList = pageName === PageName.INBOUND_PAGE_SHARED_LIST;
  const isClaimedList = pageName === PageName.INBOUND_PAGE_CLAIMED_LIST;
  const tabs = [
    {
      label: intl.formatMessage({
        id: "flows.inbound.inbound-pages.navigation-tab.shared-list",
      }),
      icon: <FileBlankListIcon />,
      isActive: isSharedList,
      onClick: () => navigate(routes.inbound.root),
    },
    {
      label: intl.formatMessage({
        id: "flows.inbound.inbound-pages.navigation-tab.claimed-list",
      }),
      icon: <FileBlankLockIcon />,
      isActive: isClaimedList,
      onClick: handleClaimedListClick,
    },
  ];

  return (
    <Page h="100%" pos="relative" isFull isBgGrey justifyContent="flex-start">
      <Flex bg="white" p="s200" w="100%" alignItems="center">
        <TitleM flex={1} noOfLines={1} textOverflow="ellipsis" marginLeft="s100">
          {intl.formatMessage({
            id: "flows.inbound.inbound-pages.header",
          })}
        </TitleM>
      </Flex>
      <TabList
        tabs={tabs}
        activeTabHighlightPosition={tabs.findIndex((tab) => tab.isActive)}
        highlightColor="pinkFlink.500"
        textAlign="center"
        gap={0}
      />
      <TabPanel scrollContainerRef={scrollContainer}>
        <Outlet />
      </TabPanel>
    </Page>
  );
}
