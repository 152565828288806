import { useMemo } from "react";

import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import { extractInboundPlan, sumStockUpdatePlanTotal } from "../stores/inboundStore/utils";

export function useInboundUnitStockDataBySku(sku: string) {
  const { isFeatureEnabled: isPartialInboundsEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.PARTIAL_INBOUNDS,
  );
  const { shelfStockLevels, inboundUnitsStockUpdates, inboundUnitsDisplayStates } = useInboundStore(
    (state) => ({
      shelfStockLevels: state.shelfStockLevels,
      inboundUnitsStockUpdates: state.inboundUnitsStockUpdates,
      inboundUnitsDisplayStates: state.inboundUnitsDisplayStates,
    }),
  );
  const { stockUpdatePlan, stockUpdated } = inboundUnitsStockUpdates[sku];
  const displayState = inboundUnitsDisplayStates[sku];
  const stockOnShelf = shelfStockLevels[sku] ?? 0;

  const stockUpdatePlanTotal = sumStockUpdatePlanTotal(stockUpdatePlan);

  const stockData = useMemo(() => {
    const baseData = {
      stockUpdated,
      stockUpdatePlanTotal,
      unitSizeForDisplay: displayState.unitSizeForDisplay,
      inboundQuantity: extractInboundPlan(stockUpdatePlan)?.quantityDelta ?? 0,
      stockOnShelf,
    };

    if (isPartialInboundsEnabled) {
      const remainder = stockUpdatePlanTotal % displayState.unitSizeForDisplay;
      const newStockUpdatePlanTotal = stockUpdatePlanTotal - remainder;
      const newDisplayQuantity = newStockUpdatePlanTotal / displayState.unitSizeForDisplay;

      return {
        ...baseData,
        stockUpdatePlanTotal: newStockUpdatePlanTotal,
        displayQuantity: newDisplayQuantity,
      };
    }

    return {
      ...baseData,
      displayQuantity: stockUpdatePlanTotal / displayState.unitSizeForDisplay,
    };
  }, [
    stockUpdated,
    stockUpdatePlanTotal,
    displayState.unitSizeForDisplay,
    stockUpdatePlan,
    stockOnShelf,
    isPartialInboundsEnabled,
  ]);

  return stockData;
}
