import { useCallback, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";

import { useCustomToast } from "shared/hooks/useCustomToast";

import {
  useDeleteInboundingListMutation,
  useEditInboundingListMutation,
} from "../queries/collaborativeInbound/collaborativeInbound.generated";
import { useInboundStore } from "./inboundStore/useInboundStore";

const EDIT_SHARED_LIST_ERROR_TOAST_ID = "edit-shared-list-error-toast";
const DELETE_DRAFT_SHARED_LIST_SUCCESS_TOAST_ID = "delete-draft-shared-list-success-toast";
const DELETE_DRAFT_SHARED_LIST_ERROR_TOAST_ID = "delete-draft-shared-list-error-toast";

export const useEditSharedListName = () => {
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const { showToastUI } = useCustomToast();
  const setSharedListName = useInboundStore((state) => state.setSharedListName);

  const handleError = useCallback(
    (error: unknown, listId: string, name: string) => {
      setIsUpdatingName(false);
      const errorMessage = error instanceof Error ? error.message : "Unknown error occurred";
      datadogRum.addError("Failed to update shared list name", {
        listId,
        name,
        errorMessage,
      });
      showToastUI({
        id: EDIT_SHARED_LIST_ERROR_TOAST_ID,
        title: "edit-shared-list-error-toast",
      });
    },
    [showToastUI],
  );

  const [updateSharedListNameMutation] = useEditInboundingListMutation();

  const editSharedListName = useCallback(
    async (listId: string, name: string) => {
      try {
        setIsUpdatingName(true);
        await updateSharedListNameMutation({
          variables: { input: { listId, name } },
        });
        setSharedListName(name);
      } catch (error) {
        handleError(error, listId, name);
      } finally {
        setIsUpdatingName(false);
      }
    },
    [handleError, setSharedListName, updateSharedListNameMutation],
  );

  return { editSharedListName, isUpdatingName };
};

export const useDeleteDraftInboundList = (refetchInboundingLists: () => void) => {
  const { showToastUI } = useCustomToast();
  const [deleteDraftInboundListMutation, { loading: isDeletingDraftInboundList }] =
    useDeleteInboundingListMutation();

  const deleteDraftInboundList = useCallback(
    async (listId: string) => {
      try {
        await deleteDraftInboundListMutation({
          variables: { input: { listId } },
        });
        refetchInboundingLists();
        showToastUI({
          status: "success",
          variant: "success",
          id: DELETE_DRAFT_SHARED_LIST_SUCCESS_TOAST_ID,
          title: "delete-draft-shared-list-success-toast",
        });
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unknown error occurred while deleting the draft inbound list.";
        datadogRum.addError("Failed to delete draft inbound list", { errorMessage });
        showToastUI({
          id: DELETE_DRAFT_SHARED_LIST_ERROR_TOAST_ID,
          title: "delete-draft-shared-list-error-toast",
        });
      }
    },
    [deleteDraftInboundListMutation, refetchInboundingLists, showToastUI],
  );

  return { deleteDraftInboundList, isDeletingDraftInboundList };
};
